import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AddressResolverResponse, KENALL } from '@ken-all/kenall';
import { from, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MonshinApi {
    api = environment.api;

    constructor(
        protected http: HttpClient,
        protected router: Router,
    ) {
    }

    getAddress(id: any): Observable<AddressResolverResponse> {
        const api = new KENALL(environment.kenallApiKey);
        return from(api.getAddress(id));
    }

    postMonshin(body: any): Observable<any> {
        return this.http.post(this.api + `interview`, body);
    }

    getNamePharmacy(id: any): Observable<any> {
        return this.http.get(this.api + `pharmacies/` + id);
    }
}
