import { Platform } from '@angular/cdk/platform';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

// カレンダーの月表示で並ぶ日付数
const DATE_LIST = 31;

export class CustomDateAdapter extends NativeDateAdapter {
    constructor(matDateLocale: string, platform: Platform) {
        super(matDateLocale, platform);
        dayjs.locale('ja');
    }

    /**
     * 日本語表記にすると、datepickerのカレンダー日付に「日」がつく
     * それを数字のみの変更する
     */
    override getDateNames(): string[] {
        return [...Array(DATE_LIST).keys()].map((i) => String(i + 1));
    }
    /**
     * customDateFormats から形式を受け取り、
     * dayjs でその形式に変更
     */
    override format(date: Date, displayFormat: string): string {
        if (!this.isValid(date)) {
            throw Error('CustomDateAdapter: Cannot format invalid date.');
        }

        return dayjs(date).format(displayFormat);
    }

    /**
     * 入力データ解析時に dayjs で幅広く変換できるようにする
     * 例:
     * 20230621 -> 2023/06/21
     * 2023/6/7 -> 2023/06/21
     *
     * 空文字の場合は null を返す
     */
    override parse(value: unknown, parseFormat?: string): Date | null {
        if (typeof value === 'number') {
            return super.parse(value);
        }
        if (!value) {
            return null;
        }
        const strict = Boolean(parseFormat);
        return dayjs(String(value), parseFormat, strict).toDate();
    }
}

/**
 * https://day.js.org/docs/en/display/format
 */
export const customDateFormats: MatDateFormats = {
    parse: {
        dateInput: 'YYYY/MM/DD',
    },
    display: {
        dateInput: 'YYYY/MM/DD',
        monthYearLabel: 'YYYY年 M月',
        dateA11yLabel: 'YYYY/MM/DD',
        monthYearA11yLabel: 'YYYY年 M月',
    },
};
