import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MonshinPageComponent } from './pages/monshin-page/monshin-page.component';

const routes: Routes = [
    {
        path: '',
        component: MonshinPageComponent,
    },
    {
        path: '403',
        component: MaintenanceComponent,
    },
    {
        path: ':id',
        component: MonshinPageComponent,
    },
    // { path: 'not-found', component: NotFoundComponent },
    // { path: 'forget-password', component: ForgetPasswordComponent},
    // { path: '**', redirectTo: 'not-found' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
