<div *ngIf="!checkStart && !checkTerm">
  <article class="glb-article">
    <h1 class="glb-heading1 type-center">
      {{namePharmacy}} 問診票
    </h1>
    <p class="glb-paragraph type-center">
      お薬を安全で効果的に使っていただくため、問診票の記入にご協力ください。
    </p>
    <div class="glb-delimited-frame">
      <p class="glb-paragraph">
        問診票の記入にあたり、個人情報の利用規約についてご確認の上、同意をいただく必要がございます。
      </p>
      <div class="glb-form-element-wrap type-center">
        <button class="glb-outline-button" type="button" (click)="checkTerm = true">
          個人情報の取り扱いについて
        </button>
      </div>
      <div class="glb-form-element-wrap type-center">
        <label class="glb-form-element-label">
          <input class="glb-checkbox" type="checkbox" [disabled]="!checkDisableTerm" [(ngModel)]="checkTermcheckBox" (change)="checkAgree($event)"/>
          <span class="glb-checkbox-label">上記規約の内容に同意しました。</span>
        </label>
      </div>
    </div>
    <ul class="glb-button-list type-center">
      <li class="glb-button-item">
        <button class="glb-fill-button type-large" type="button" [disabled]="!checkAgreeTerm" (click)="startFilling()">
          記入を開始する
        </button>
      </li>
    </ul>
  </article>
</div>
<!--Page 0-->

<div *ngIf="checkTerm" >
  <app-privacy-policy [policy]="policy" (close)="checkTerm = false"></app-privacy-policy>
</div>
<div *ngIf="checkStart">

  <form [formGroup]="formAgree">
    <article  class="glb-step-article" *ngIf="!isSubmitted" >
      <!--  Page 1-->
    <ng-container *ngIf="page === 1">
      <header class="glb-step-header">
        <h1 class="glb-heading1 type-center">特に注記のないものは必須項目です</h1>
      </header>
      <div class="glb-step-section-wrap">
        <section class="glb-step-section">
          <h2 class="glb-heading2">お名前</h2>
          <h3 class="glb-heading3">漢字</h3>
          <ul class="glb-unordered-list">
            <li class="glb-list-item">
              <label class="glb-form-element-label">
                <span class="glb-form-element-prefix">姓</span>
                <input type="text" class="glb-text-field" formControlName="last_name" (blur)="onTrim('last_name')"
                       [ngClass]="{'element-invalid-border': formAgree.controls['last_name'].value === '' && (formAgree.controls['last_name'].dirty || checkFocusLastName)}"/>
              </label>
              <span *ngIf="formAgree.controls['last_name'].value === '' && (formAgree.controls['last_name'].dirty || checkFocusLastName)" class="glb-form-element-invalid-message">{{errorRequired}}</span>
            </li>
            <li class="glb-list-item">
              <label class="glb-form-element-label">
                <span class="glb-form-element-prefix">名</span>
                <input type="text" class="glb-text-field" formControlName="first_name" (blur)="onTrim('first_name')"
                       [ngClass]="{'element-invalid-border': formAgree.controls['first_name'].value === '' && (formAgree.controls['first_name'].dirty || checkFocusFirstName)}"/>
              </label>
              <span *ngIf="formAgree.controls['first_name'].value === '' && (formAgree.controls['first_name'].dirty || checkFocusFirstName)" class="glb-form-element-invalid-message">{{errorRequired}}</span>

            </li>
          </ul>
          <h3 class="glb-heading3">カナ</h3>
          <ul class="glb-unordered-list">
            <li class="glb-list-item">
              <label class="glb-form-element-label">
                <span class="glb-form-element-prefix">セイ</span>
                <input type="text" class="glb-text-field" formControlName="last_name_kana" (blur)="onTrim('last_name_kana')" pattern="{{patternKana}}"
                [ngClass]="{'element-invalid-border': (formAgree.controls['last_name_kana'].value === '' && (formAgree.controls['last_name_kana'].dirty || checkFocusLastKana)) || formAgree.controls['last_name_kana'].errors?.['maxlength']}"/>
              </label>
              <span *ngIf="formAgree.controls['last_name_kana'].value === '' && (formAgree.controls['last_name_kana'].dirty || checkFocusLastKana)" class="glb-form-element-invalid-message">{{errorRequired}}</span>
              <span *ngIf="formAgree.controls['last_name_kana'].errors?.['pattern']" class="glb-form-element-invalid-message">{{errorKana}}</span>
              <span *ngIf="formAgree.controls['last_name_kana'].errors?.['maxlength']" class="glb-form-element-invalid-message" >{{errorKanaMaxLength}}</span>
            </li>
            <li class="glb-list-item">
              <label class="glb-form-element-label">
                <span class="glb-form-element-prefix">メイ</span>
                <input type="text" class="glb-text-field" formControlName="first_name_kana" (blur)="onTrim('first_name_kana')" pattern="{{patternKana}}"
                       [ngClass]="{'element-invalid-border':  (formAgree.controls['first_name_kana'].value === '' && (formAgree.controls['first_name_kana'].dirty || checkFocusFirstKana)) || formAgree.controls['first_name_kana'].errors?.['maxlength']}"/>
              </label>
              <span *ngIf="formAgree.controls['first_name_kana'].value === '' && (formAgree.controls['first_name_kana'].dirty || checkFocusFirstKana)" class="glb-form-element-invalid-message">{{errorRequired}}</span>
              <span *ngIf="formAgree.controls['first_name_kana'].errors?.['pattern']" class="glb-form-element-invalid-message">{{errorKana}}</span>
              <span *ngIf="formAgree.controls['first_name_kana'].errors?.['maxlength']" class="glb-form-element-invalid-message">{{errorKanaMaxLength}}</span>
            </li>
          </ul>
        </section>
        <section class="glb-step-section type-birthday">
          <h2 class="glb-heading2">生年月日</h2>
          <ul class="glb-unordered-list">
            <li class="glb-list-item type-flex">
              <div>
                <div class="glb-dropdown">
                  <label class="glb-form-element-label type-w100">
                    <select [(ngModel)]="era"
                            [ngModelOptions]="{standalone: true}"
                            class="glb-dropdown-wrap"
                            (change)="onGettingEra(era)"
                    >
                      <option *ngFor="let era of appConfig.ERA">{{era.name}}</option>
                    </select>
                  </label>
                </div>
              </div>
              <div data-test-id="year-select-wrapper">
                <div class="glb-dropdown mg-0">
                  <label class="glb-form-element-label type-w100">
                    <select [(ngModel)]="year"
                            [ngModelOptions]="{standalone: true}"
                            class="glb-dropdown-wrap"
                            (change)="yearChange()" (blur)="isValidYear()"
                            [ngClass]="{'element-invalid-border-select': this.year == '' && checkYear}"
                            data-test-id="year-select">
                      <option *ngFor="let eraYear of eraYearList" [value]="eraYear?.year">{{eraYear?.year}}</option>
                    </select>
                  </label>
                </div>
                <div *ngIf="this.year == '' && checkYear" class="glb-form-element-invalid-message">{{errorRequired}}</div>
              </div>
              <span class="glb-form-element-suffix mt-11">年</span>
            </li>
            <li class="glb-list-item type-flex">
              <div data-test-id="month-select-wrapper">
                <div class="glb-dropdown mg-0">
                  <label class="glb-form-element-label type-w100">
                    <select [(ngModel)]="month"
                            [ngModelOptions]="{standalone: true}"
                            class="glb-dropdown-wrap" (change)="monthChange(month)" (blur)="isValidMonth()"
                            [ngClass]="{'element-invalid-border-select': this.month == '' && checkMonth}"
                            data-test-id="month-select">
                      <option *ngFor="let month of appConfig.MONTH">{{month?.month}}</option>
                    </select>
                  </label>
                </div>
                <div *ngIf="this.month == '' && checkMonth" class="glb-form-element-invalid-message">{{errorRequired}}</div>
              </div>
              <span class="glb-form-element-suffix mt-11">月</span>
              <div data-test-id="day-select-wrapper">
                <div class="glb-dropdown mg-0">
                  <label class="glb-form-element-label type-w100">
                    <select [(ngModel)]="date"
                            [ngModelOptions]="{standalone: true}"
                            class="glb-dropdown-wrap" (blur)="isValidDate()"
                            [ngClass]="{'element-invalid-border-select': this.date == '' && checkDate}"
                            data-test-id="day-select">
                      <option *ngFor="let month of eraDayList">{{month?.day}}</option>
                    </select>
                  </label>
                </div>
                <div *ngIf="this.date == '' && checkDate" class="glb-form-element-invalid-message">{{errorRequired}}</div>
              </div>

              <span class="glb-form-element-suffix mt-11">日</span>
            </li>
          </ul>
        </section>
        <section class="glb-step-section">
          <h2 class="glb-heading2">性別</h2>
          <ul class="glb-radio-list">
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="sex"  value="0" formControlName="sex" (change)="sexChange($event)"/>
                <span class="glb-radio-label">男性</span>
              </label>
            </li>
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="sex" value="1" formControlName="sex" (change)="sexChange($event)"/>
                <span class="glb-radio-label">女性</span>
              </label>
            </li>
          </ul>
        </section>
        <section class="glb-step-section">
          <h2 class="glb-heading2">住所</h2>
          <ul class="glb-unordered-list">
            <li class="glb-list-item">
              <label class="glb-form-element-label type-fit-content">
                <span class="glb-form-element-prefix">郵便番号</span>
                <!-- type="tel": iOSの「かな入力」モードのIMEでは、入力文字列を確定する前にfocusoutしてしまうと、FormControlが入力中の文字列を認識できないため -->
                <input type="tel" class="glb-text-field type-w100" formControlName="postal_code"
                       [ngClass]="{'element-invalid-border': ((formAgree.controls['postal_code'].value.length > 8 ||
                       isPostalCodeRegexInvalid || validRequiredPostalCode) ||validLengthPostalCode) && (formAgree.controls['postal_code'].dirty || checkFocusPostalCode)}"
                       (blur)="onTrim('postal_code')"/>
              </label>
              <div *ngIf="validLengthPostalCode && !isPostalCodeRegexInvalid && checkValidNumber" class="glb-form-element-invalid-message">郵便番号を正しく入力してください</div>
              <div *ngIf="validRequiredPostalCode && (formAgree.controls['postal_code'].dirty || checkFocusPostalCode)" class="glb-form-element-invalid-message">{{errorRequired}}</div>
              <div *ngIf="(!isPostalCodeRegexInvalid && formAgree.controls['postal_code'].dirty
              && (!checkValidNumber && validLengthPostalCode)) ||( isPostalCodeRegexInvalid && !checkValidNumber && !validLengthPostalCode)
               || (validLengthPostalCode && !checkValidNumber && !isPostalCodeRegexInvalid)"
                   class="glb-form-element-invalid-message">数値で入力してください</div>
            </li>
            <li class="glb-list-item">
              <label class="glb-form-element-label type-column">
                <span class="glb-form-element-prefix">住所</span>
                <textarea
                  class="glb-number"
                  name="textarea"
                  cols="0"
                  rows="4"
                  formControlName="address" (blur)="onTrim('address')" [(ngModel)]="address"
                  [ngClass]="{'element-invalid-border': (address == '' && formAgree.controls['address'].value === '') && (formAgree.controls['address'].dirty || checkFocusAddress)}"
                ></textarea>
                <span *ngIf="(address == '' && formAgree.controls['address'].value === '' )&& (formAgree.controls['address'].dirty || checkFocusAddress)" class="glb-form-element-invalid-message">{{errorRequired}}</span>
              </label>
            </li>
          </ul>
        </section>
        <section class="glb-step-section">
          <h2 class="glb-heading2">電話番号（連絡がつくもの）</h2>
          <ul class="glb-unordered-list">
            <li class="glb-list-item">
              <label class="glb-form-element-label">
                <input type="text" pattern="{{patternNumber}}" class="glb-text-field" formControlName="phone_number" placeholder="0312345678（ハイフンなし）" (blur)="onTrim('phone_number')"
                       [ngClass]="{'element-invalid-border': formAgree.controls['phone_number'].value === '' && (formAgree.controls['phone_number'].dirty || checkFocusPhone)}"
                />
              </label>
              <span *ngIf="formAgree.controls['phone_number'].value === '' && (formAgree.controls['phone_number'].dirty || checkFocusPhone)" class="glb-form-element-invalid-message">{{errorRequired}}</span>
              <span *ngIf="formAgree.controls['phone_number'].errors?.['pattern']" class="glb-form-element-invalid-message">半角数字（ハイフンなし）で入力してください</span>
            </li>
          </ul>
        </section>
      </div>
    </ng-container>
    <!--  page 2-->
    <ng-container *ngIf="page === 2">
      <header class="glb-step-header">
        <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
      </header>
      <div class="glb-step-section-wrap">
        <section class="glb-step-section">
          <h2 class="glb-heading2">身長と体重を入力してください（任意）</h2>
          <ul class="glb-unordered-list">
            <li class="glb-list-item type-flex-column">
              <label class="glb-form-element-label type-fit-content">
                <span class="glb-form-element-prefix">身長</span>
                <input
                  type="text" pattern="{{patternNumber}}" maxlength="3"
                  class="glb-number type-w100"
                  formControlName="height" [ngClass]="{'element-invalid-border': formAgree.controls['height'].invalid}"
                />
                <span class="glb-form-element-suffix">cm</span>
              </label>
              <span class="glb-form-element-invalid-message" *ngIf="formAgree.controls['height'].invalid">{{errorInteger}}</span>
            </li>
            <li class="glb-list-item type-flex-column">
              <label class="glb-form-element-label type-fit-content">
                <span class="glb-form-element-prefix">体重</span>
                <input
                  type="text" pattern="{{patternNumber}}" maxlength="3"
                  class="glb-number type-w100"
                  formControlName="weight" [ngClass]="{'element-invalid-border': formAgree.controls['weight'].invalid}"
                />
                <span class="glb-form-element-suffix">kg</span>
              </label>
              <span class="glb-form-element-invalid-message" *ngIf="formAgree.controls['weight'].invalid">{{errorInteger}}</span>
            </li>
          </ul>
        </section>
      </div>
    </ng-container>
    <!--  page 3-->
      <ng-container *ngIf="page == 3">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">お薬手帳はお持ちですか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" formControlName="prescription_note" value="0"  />
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" formControlName="prescription_note" value="1"  />
                  <span class="glb-radio-label">忘れた</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" formControlName="prescription_note" value="2" />
                  <span class="glb-radio-label">不要</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" formControlName="prescription_note" value="3" />
                  <span class="glb-radio-label">作成希望</span>
                </label>
              </li>
            </ul>
          </section>
          <section class="glb-step-section">
            <h2 class="glb-heading2"><span msbTooltip
              [isText]="true"
              [adjustValueX]="-65"
              [adjustValueY]="-8"
              [timer]="8000"
              message="ジェネリック医薬品（後発医薬品）は、新薬（先発
医薬品）の特許が切れた後に製造販売される、新薬
と同一の有効成分を同一量含み、効き目が同等な医
薬品のことです。"
              >ジェネリック医薬品</span>を希望しますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="generic_state" formControlName="generic_state" value="0" />
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input
                    type="radio"
                    class="glb-radio"
                    name="generic_state"
                    value="1"
                    formControlName="generic_state"
                  />
                  <span class="glb-radio-label">どちらでもよい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="generic_state" value="2" formControlName="generic_state" />
                  <span class="glb-radio-label">希望しない</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input formControlName="generic_state"
                    type="radio"
                    class="glb-radio"
                    name="generic_state"
                    value="3"
                  />
                  <span class="glb-radio-label">特定の薬のみ希望する</span>
                </label>
              </li>
            </ul>
            <div *ngIf="formAgree.controls['generic_state'].value == 3">
              <h2 class="glb-heading3">薬剤名を記入してください（任意）</h2>
              <textarea  class="glb-number"
                         name="textarea"
                         cols="0"
                         rows="4" formControlName="medicine_name" (blur)="onTrim('medicine_name')"> </textarea>
              <label class="glb-form-element-label">
                <input class="glb-checkbox" type="checkbox" value="true" formControlName="written_in_prescription_note_desired_medicines"/>
                <span class="glb-checkbox-label">手帳に記載あり</span>
              </label>
            </div>
          </section>
        </div>

      </ng-container>
    <!--  page 4-->
      <ng-container *ngIf="page == 4">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">現在病気の治療中ですか？または最近病気にかかったことはありますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input
                    type="radio"
                    class="glb-radio"
                    name="illness_state"
                    value="0"
                    formControlName="illness_state"
                  />
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input
                    type="radio"
                    class="glb-radio"
                    name="illness_state"
                    formControlName="illness_state"
                    value="1"
                  />
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
            <div
              *ngIf="formAgree.controls['illness_state'].value == 0 && formAgree.controls['illness_state'].value !== ''">
              <h2 class="glb-heading3">病気の名称を選択してください（複数可）</h2>
              <ul class="glb-checkbox-list">
                <li class="glb-checkbox-item " *ngFor="let obj of diseaseList" [class.type-hidden-item]="obj.name ==='その他'">
                  <label class="glb-form-element-label">
                    <input class="glb-checkbox" type="checkbox" [checked]="obj.checked === true"
                           (change)="changeCheckDisease($event)" [value]="obj.name"/>
                    <span class="glb-checkbox-label">{{obj.name}}</span>
                  </label>
                </li>
              </ul>
              <h2 class="glb-heading3">リストにない病気の場合、名称を記入してください</h2>
              <textarea class="glb-number"
                        name="textarea"
                        cols="0"
                        rows="4" formControlName="other_disease" (blur)="onTrim('other_disease')"
                        (keyup)="checkInputValueOfDiseaseText($event)"></textarea>
            </div>
          </section>
        </div>
      </ng-container>
    <!--  page 5-->
      <ng-container *ngIf="page == 5">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">アレルギーはありますか？</h2>
          </section>
          <ul class="glb-radio-list">
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="allergies_state"  value="0" formControlName="allergies_state"/>
                <span class="glb-radio-label">はい</span>
              </label>
            </li>
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="allergies_state" value="1" formControlName="allergies_state"/>
                <span class="glb-radio-label">いいえ</span>
              </label>
            </li>
          </ul>
          <!--    yes-->
          <div *ngIf="formAgree.controls['allergies_state'].value == 0 && formAgree.controls['allergies_state'].value !== ''">
            <h2 class="glb-heading3">アレルギーの対象を選択してください（複数可）</h2>
            <ul class="glb-checkbox-list">
              <li class="glb-checkbox-item" *ngFor="let obj of allergiesValue" [class.type-hidden-item]="obj.name ==='その他'">
                <label class="glb-form-element-label">
                  <input class="glb-checkbox" type="checkbox"
                         [checked]="obj.checked" (change)="checkAllergiesValue($event)"
                         [value]="obj.name"/>
                  <span class="glb-checkbox-label">{{obj.name}}</span>
                </label>
              </li>
            </ul>
            <h2 class="glb-heading3">リストにないアレルギーの場合、対象名を記入してください</h2>
            <textarea class="glb-number"
                      name="textarea"
                      cols="0"
                      rows="4" formControlName="other_allergies" (blur)="onTrim('other_allergies')"
                      (keyup)="checkInputValueOfAllergiesText($event)"></textarea>
          </div>
        </div>
      </ng-container>
    <!--  page 6-->
      <ng-container *ngIf="page == 6">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2"> 薬で副作用が起きたことはありますか？</h2>
          </section>
          <ul class="glb-radio-list">
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="side_effect_state"  value="0" formControlName="side_effect_state"/>
                <span class="glb-radio-label">はい</span>
              </label>
            </li>
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="side_effect_state" value="1" formControlName="side_effect_state"/>
                <span class="glb-radio-label">いいえ</span>
              </label>
            </li>
          </ul>
<!--          yes-->
          <div *ngIf="formAgree.controls['side_effect_state'].value == 0 && formAgree.controls['side_effect_state'].value != ''">
            <h2 class="glb-heading3">
              <div>副作用の詳細を記入してください</div>
            <div>（薬の名称、症状など）</div>
            </h2>
            <textarea class="glb-number"
                      name="textarea"
                      cols="0"
                      [ngClass]="{'element-invalid-border': formAgree.controls['side_effect_case'].value === '' && (formAgree.controls['side_effect_case'].dirty || checkFocusSide)}"
                      rows="4" formControlName="side_effect_case" (blur)="onTrim('side_effect_case')"></textarea>
            <div *ngIf="formAgree.controls['side_effect_case'].value === '' && (formAgree.controls['side_effect_case'].dirty || checkFocusSide)" class="glb-form-element-invalid-message">{{errorRequired}}</div>

          </div>
        </div>
      </ng-container>
    <!--  page 7-->
      <ng-container *ngIf="page == 7">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">他の病院や薬局で薬をもらっていますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="adjuvant_remedy_state" value="0" formControlName="adjuvant_remedy_state"/>
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="adjuvant_remedy_state" value="1" formControlName="adjuvant_remedy_state"/>
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
            <div *ngIf="formAgree.controls['adjuvant_remedy_state'].value == 0 && formAgree.controls['adjuvant_remedy_state'].value != ''">
              <h2 class="glb-heading3">薬剤名を記入してください（任意）</h2>
              <textarea  class="glb-number"
                         name="textarea"
                         cols="0"
                         rows="4" formControlName="adjuvant_remedy_name" (blur)="onTrim('adjuvant_remedy_name')"></textarea>

              <label class="glb-form-element-label">
                <input class="glb-checkbox" type="checkbox" formControlName="written_in_prescription_note_adjuvant_remedy"/>
                <span class="glb-checkbox-label">手帳に記載あり</span>
              </label>
            </div>
          </section>

          <section class="glb-step-section">
            <h2 class="glb-heading2"> 市販薬やサプリメント・漢方薬などを飲んでいますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="supplement_state" value="0" formControlName="supplement_state"/>
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="supplement_state" value="1" formControlName="supplement_state"/>
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
            <!--    yes-->
            <div *ngIf="formAgree.controls['supplement_state'].value == 0 && formAgree.controls['supplement_state'].value != ''">
              <h2 class="glb-heading3">薬剤名を記入してください（任意）</h2>
              <textarea class="glb-number"
                        name="textarea"
                        cols="0"
                        rows="4" formControlName="supplement_name" (blur)="onTrim('supplement_name')"></textarea>

            </div>
          </section>

        </div>
      </ng-container>

    <!--  page 8-->
      <ng-container *ngIf="formAgree.controls['sex'].value == 1 && page == 8">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">妊娠している、またはその可能性がありますか?</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="pregnancy_state" value="0" formControlName="pregnancy_state"/>
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="pregnancy_state" value="1" formControlName="pregnancy_state"/>
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
            <!--    yes-->
            <div *ngIf="formAgree.controls['pregnancy_state'].value == 0 && formAgree.controls['pregnancy_state'].value != ''">
              <h2 class="glb-heading3">お分かりになれば記入してください（任意）</h2>
              <ul class="glb-unordered-list">
                <li class="glb-list-item type-flex-column">
                  <label class="glb-form-element-label type-h24 type-fit-content">
                    <span class="glb-form-element-prefix">出産予定日</span>
                    <date-picker [formGroup]="formAgree" [dateOfBirth]="formAgree.controls['date_of_birth'].value"></date-picker>
                  </label>
                  <span class="glb-form-element-invalid-message" *ngIf="formAgree.controls['date_of_birth'].invalid">{{errorDate}}</span>
               </li>
              </ul>
            </div>
          </section>
          <section class="glb-step-section">
            <h2 class="glb-heading2">現在、授乳をしていますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="breast_feeding" value="0" formControlName="breast_feeding"/>
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="breast_feeding" value="1" formControlName="breast_feeding"/>
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
          </section>

        </div>
      </ng-container>

    <!-- page 9-->
      <ng-container *ngIf="page === (pageTotalView === 14 ? 9 : 8)">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">お酒を飲みますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="drink" value="0" formControlName="drink"/>
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="drink" value="1" formControlName="drink"/>
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
            <!--    yes-->
            <div *ngIf="formAgree.controls['drink'].value == 0 && formAgree.controls['drink'].value != ''">
              <h2 class="glb-heading3">頻度はどの程度ですか？</h2>
              <ul class="glb-radio-list">
                <li class="glb-radio-item">
                  <label class="glb-form-element-label">
                    <input type="radio" class="glb-radio" name="frequency" value="0" formControlName="frequency"/>
                    <span class="glb-radio-label">たまに</span>
                  </label>
                </li>
                <li class="glb-radio-item">
                  <label class="glb-form-element-label">
                    <input type="radio" class="glb-radio" name="frequency" value="1" formControlName="frequency"/>
                    <span class="glb-radio-label">毎日</span>
                  </label>
                </li>
              </ul>
            </div>
          </section>

          <section class="glb-step-section">
            <h2 class="glb-heading2">車の運転をしますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="drive" value="0" formControlName="drive"/>
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="drive" value="1" formControlName="drive"/>
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
          </section>

          <section class="glb-step-section">
            <h2 class="glb-heading2">喫煙をしますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="smoke_state" value="0" formControlName="smoke_state"/>
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="smoke_state" value="1" formControlName="smoke_state"/>
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
            <div *ngIf="formAgree.controls['smoke_state'].value == 0 && formAgree.controls['smoke_state'].value != ''">
              <h2 class="glb-heading3">喫煙量は１日何本くらいですか？</h2>
              <ul class="glb-unordered-list">
                <li class="glb-list-item type-flex-column">
                  <label class="glb-form-element-label type-fit-content">
                    <span class="glb-form-element-prefix">１日</span>
                    <input type="text" pattern="{{patternNumber}}" required maxlength="3" class="glb-number type-w100"
                      [ngClass]="{'element-invalid-border': formAgree.controls['smoke_count'].invalid && formAgree.controls['smoke_count'].touched}"
                      formControlName="smoke_count">
                    <span class="glb-form-element-suffix">本</span>
                  </label>
                  <span class="glb-form-element-invalid-message" *ngIf="formAgree.controls['smoke_count'].errors?.['required'] && formAgree.controls['smoke_count'].touched">{{errorRequired}}</span>
                  <span class="glb-form-element-invalid-message" *ngIf="formAgree.controls['smoke_count'].errors?.['pattern']">{{errorInteger}}</span>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </ng-container>
    <!--  page 10-->
      <ng-container *ngIf="page === (pageTotalView === 14 ? 10 : 9)">
      <header class="glb-step-header">
        <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
      </header>
      <div class="glb-step-section-wrap">
        <section class="glb-step-section">
          <h2 class="glb-heading2">高所作業または危険性のある機械の操作をしますか？</h2>
          <ul class="glb-radio-list">
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="dangerous_work" value="0" formControlName="dangerous_work"/>
                <span class="glb-radio-label">はい</span>
              </label>
            </li>
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="dangerous_work" value="1" formControlName="dangerous_work"/>
                <span class="glb-radio-label">いいえ</span>
              </label>
            </li>
          </ul>
        </section>
        <section class="glb-step-section">
          <h2 class="glb-heading2">危険なスポーツ（例：スカイダイビング、ロッククライミングなど）をしますか？</h2>
          <ul class="glb-radio-list">
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="dangerous_hobby" value="0" formControlName="dangerous_hobby"/>
                <span class="glb-radio-label">はい</span>
              </label>
            </li>
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="dangerous_hobby" value="1" formControlName="dangerous_hobby"/>
                <span class="glb-radio-label">いいえ</span>
              </label>
            </li>
          </ul>
        </section>
        <section class="glb-step-section">
          <h2 class="glb-heading2">規則正しく食事をしていますか？</h2>
          <ul class="glb-radio-list">
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="meal_regularity" value="0" formControlName="meal_regularity"/>
                <span class="glb-radio-label">はい</span>
              </label>
            </li>
            <li class="glb-radio-item">
              <label class="glb-form-element-label">
                <input type="radio" class="glb-radio" name="meal_regularity" value="1" formControlName="meal_regularity"/>
                <span class="glb-radio-label">いいえ</span>
              </label>
            </li>
          </ul>
        </section>
        <section class="glb-step-section">
          <h2 class="glb-heading2">よく摂取するものがあれば選択してください（任意・複数可）</h2>
          <ul class="glb-checkbox-list">
            <li class="glb-checkbox-item" *ngFor="let obj of frequentlyEatFoodList">
              <label class="glb-form-element-label">
                <input type="checkbox" class="glb-checkbox" [checked]="obj.checked" (change)="checkFrequentlyEatFood($event)" [value]="obj.id"/>
                <span class="glb-checkbox-label">{{obj.name}}</span>
              </label>
            </li>
          </ul>
        </section>
        <section class="glb-step-section">
          <h2 class="glb-heading2">体質で該当するものがあれば選択してください（任意・複数可）</h2>
          <ul class="glb-checkbox-list">
            <li class="glb-checkbox-item" *ngFor="let obj of diathesisList">
              <label class="glb-form-element-label">
                <input type="checkbox" class="glb-checkbox" [checked]="obj.checked" (change)="checkDiathesis($event)" [value]="obj.id"/>
                <span class="glb-checkbox-label">{{obj.name}}</span>
              </label>
            </li>
          </ul>
        </section>
      </div>
      </ng-container>
    <!-- page 11-->
      <ng-container *ngIf="page === (pageTotalView === 14 ? 11 : 10)">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">嗜好品や生活習慣で該当するものがあれば選択してください（任意・複数可）</h2>
            <ul class="glb-radio-list d-grid">
              <div class="glb-radio-item" *ngFor="let obj of favoriteFoodLifestyleList">
                <label class="glb-form-element-label">
                  <input type="checkbox" class="glb-checkbox" (change)="checkFavoriteFoodLifestyleList($event)"
                         [checked]="obj.checked" [value]="obj.id"/>
                  <span class="glb-checkbox-label">{{obj.name}}</span>
                </label>
              </div>
            </ul>
          </section>
        </div>
      </ng-container>
    <!--page 12-->
      <ng-container *ngIf="page === (pageTotalView === 14 ? 12 : 11)">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">コンタクトレンズをしていますか？</h2>
            <ul class="glb-radio-list">
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="contact_lens_state" value="0" formControlName="contact_lens_state"/>
                  <span class="glb-radio-label">はい</span>
                </label>
              </li>
              <li class="glb-radio-item">
                <label class="glb-form-element-label">
                  <input type="radio" class="glb-radio" name="contact_lens_state" value="1" formControlName="contact_lens_state"/>
                  <span class="glb-radio-label">いいえ</span>
                </label>
              </li>
            </ul>
          </section>
          <!--    yes-->
          <div *ngIf="formAgree.controls['contact_lens_state'].value == 0 && formAgree.controls['contact_lens_state'].value != ''">
            <h2 class="glb-heading3">種類を選択してください（複数可）</h2>
            <ul class="glb-checkbox-list">
              <li class="glb-checkbox-item" *ngFor="let obj of contactLensValueList">
                <label class="glb-form-element-label">
                  <input type="checkbox" class="glb-checkbox" (change)="checkContactLensValue($event)"
                         [checked]="obj.checked" [value]="obj.id"/>
                  <span class="glb-checkbox-label">{{obj.name}}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    <!-- page 13-->
      <ng-container *ngIf="page === (pageTotalView === 14 ? 13 : 12)">
        <header class="glb-step-header">
          <h1 class="glb-heading1 type-center">残{{pageTotalView-page}}ページ</h1>
        </header>
        <div class="glb-step-section-wrap">
          <section class="glb-step-section">
            <h2 class="glb-heading2">苦手な形状の薬があれば教えてください（任意・複数可）</h2>
            <ul class="glb-checkbox-list">
              <li class="glb-checkbox-item" *ngFor="let obj of notGoodDosageFormList">
                <label class="glb-form-element-label">
                  <input type="checkbox" class="glb-checkbox" (change)="checkNotGoodDosageForm($event)"
                         [checked]="obj.checked" [value]="obj.id"/>
                  <span class="glb-checkbox-label">{{obj.name}}</span>
                </label>
              </li>
            </ul>
          </section>
        </div>
      </ng-container>

      <!-- page 14-->
      <ng-container *ngIf="page === (pageTotalView === 14 ? 14 : 13)">
        <div class="glb-step-section-wrap type-confirm">
          <p class="glb-paragraph type-center">以上で終了です。<br>問題なければ「送信」を押してください。</p>

          <ul class="glb-button-list type-center">
              <li class="glb-button-item">
                <button class="glb-fill-button type-large" type="button" (click)="actionSave()">送信</button>
              </li>
          </ul>
        </div>
      </ng-container>

      <footer class="glb-step-footer">
        <ul class="glb-button-list type-center">
          <li class="glb-button-item">
            <button class="glb-text-button" type="button" *ngIf="!isSubmitted" (click)="previousPage()" [ngClass]="{'is-hidden': page === 1}">前へ</button>
          </li>
          <li class="glb-button-item">
            <button class="glb-fill-button" type="button" [ngClass]="{'is-hidden': page === (pageTotalView === 14 ? 14 : 13)}" [disabled]="isDisableButton()" (click)="nextPage()">次へ</button>
          </li>
        </ul>
      </footer>
    </article>
  </form>
    <!-- end-->
  <article class="glb-last-article" *ngIf="isSubmitted">
    <p class="glb-paragraph type-center">ご協力いただきありがとうございました。</p>
    <ul class="glb-button-list type-center">
      <li class="glb-button-item">
        <button class="glb-text-button type-large" type="button" (click)="closeBrow()">閉じる</button>
      </li>
    </ul>
  </article>

</div>

<ngx-spinner size="large" color="#5ACEC6" type="ball-clip-rotate" [fullScreen]="true"></ngx-spinner>
