import { Component, OnInit, ViewChild } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import * as AWS from 'aws-sdk';
import { HandlerErrorCode } from 'aws-sdk/clients/cloudcontrol';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { APP_CONFIG } from '../../config/era';
import { MonshinApi } from '../services/monshin.service';

@Component({
    selector: 'app-monshin-page',
    templateUrl: './monshin-page.component.html',
    styleUrls: ['./monshin-page.component.css'],
})
export class MonshinPageComponent implements OnInit {
    @ViewChild('inputFormat')
    inputFormat: any;

    appConfig = APP_CONFIG;
    checkStart = false;
    checkTerm = false;
    checkDisableTerm = false;
    checkAgreeTerm: any;
    page = 1;
    pageTotalView: any = '--';
    totalPage = 14;
    checkEndPage = false;
    formAgree: UntypedFormGroup;
    yearBirth: any;
    clickOne = false;
    era: any = this.appConfig.ERA[2].name;
    year: any = '';
    eraYearList = this.appConfig.ERA[2].years;
    eraDayList: any = [];
    month: any = '';
    date: any = '';
    isDisable11thPage = true;
    diseaseCheckedList = [];
    checkScrollPage5 = false;
    checkScrollPage4 = false;
    errorRequired = '入力必須です';
    errorKana = 'カナで入力してください';
    errorInteger = '0~999までの整数を半角で入力してください';
    errorKanaMaxLength = '100文字以内で入力してください';
    errorDate = '日付が不正です';
    validRequiredPostalCode = false;
    validLengthPostalCode = false;
    checkFocusLastName = false;
    checkFocusFirstName = false;
    checkFocusLastKana = false;
    checkFocusFirstKana = false;
    checkFocusPostalCode = false;
    checkFocusAddress = false;
    checkFocusPhone = false;
    checkFocusOtherDisease = false;
    checkFocusOtherAllergies = false;
    checkFocusSide = false;
    checkFocusPregnancy = false;
    checkFocusSmock = false;
    checkYear = false;
    checkMonth = false;
    checkDate = false;
    policy: string = '';

    diseaseList = [
        { name: '脳梗塞', checked: false },
        { name: 'てんかん', checked: false },
        { name: '認知症', checked: false },
        { name: '狭心症', checked: false },
        { name: '不整脈', checked: false },
        { name: '肝機能障害', checked: false },
        { name: '腎機能障害', checked: false },
        { name: '高血圧症', checked: false },
        { name: '1型糖尿病', checked: false },
        { name: '2型糖尿病', checked: false },
        { name: '脂質異常症', checked: false },
        { name: '胃炎・消化性潰瘍', checked: false },
        { name: '気管支喘息', checked: false },
        { name: '骨粗鬆症', checked: false },
        { name: '前立腺肥大症', checked: false },
        { name: '緑内障', checked: false },
        { name: '白内障', checked: false },
        { name: '鼻炎・花粉症', checked: false },
        { name: 'その他', checked: false },
    ];
    allergiesCheckValueList = [];
    allergiesValue = [
        { name: '卵', checked: false },
        { name: '牛乳', checked: false },
        { name: 'アルコール', checked: false },
        { name: 'ヨード', checked: false },
        { name: 'その他', checked: false },
    ];
    frequentlyEatFoodList = [
        { id: 0, name: '乳製品 ', checked: false },
        { id: 1, name: 'コーヒー ', checked: false },
        { id: 2, name: '納豆 ', checked: false },
        { id: 3, name: 'グレープフルーツ ', checked: false },
    ];
    frequentlyEatFoodListID = [];
    diathesisList = [
        { id: 0, name: 'かぶれやすい ', checked: false },
        { id: 1, name: '便秘がち ', checked: false },
        { id: 2, name: '下痢しやすい ', checked: false },
        { id: 3, name: '胃が弱い ', checked: false },
    ];
    diathesisListID = [];
    contactLensValueList = [
        { id: 0, name: 'ハード ', checked: false },
        { id: 1, name: 'ソフト ', checked: false },
        { id: 2, name: '使い捨て ', checked: false },
    ];
    contactLensValueListID = [];
    favoriteFoodLifestyleList = [
        { id: 0, name: '甘いものをよく食べる ', checked: false },
        { id: 1, name: '脂っこいものをよく食べる ', checked: false },
        { id: 2, name: '塩気の強いものが好き ', checked: false },
        { id: 3, name: '夕食後すぐ就寝する ', checked: false },
        { id: 4, name: '間食が多い ', checked: false },
        { id: 5, name: '朝食を抜くことが多い ', checked: false },
        { id: 6, name: '食べるのが早い ', checked: false },
        { id: 7, name: 'あまり運動をしない ', checked: false },
    ];
    favoriteFoodLifestyleListID = [];
    notGoodDosageFormList = [
        { id: 0, name: '粉 ', checked: false },
        { id: 1, name: 'シロップ ', checked: false },
        { id: 2, name: 'カプセル ', checked: false },
        { id: 3, name: '錠剤 ', checked: false },
    ];
    namePharmacy = '';
    pharmacyId = '';
    notGoodDosageFormListID = [];
    isSubmitted = false;
    api = environment.api;
    address = '';
    checkTermcheckBox = false;
    phoneNumber = '';
    isPostalCodeRegexInvalid = false;
    checkValidNumber = false;
    postal_code = '';

    patternKana = '^([a-zA-Zァ-ーｧ-ﾝﾞﾟ]|ー)+$';
    patternNumber = '^[0-9]+$';
    @ViewChild('divMonshin')
    divMonshin: any;

    constructor(
        private fb: UntypedFormBuilder,
        protected monshinApi: MonshinApi,
        protected ngxSpinner: NgxSpinnerService,
        protected toaStr: ToastrService,
    ) {
        this.formAgree = this.fb.group({});
    }

    async ngOnInit(): Promise<void> {
        const queryString = window.location.href;
        const keyUrl = queryString.lastIndexOf('/');
        const idPharmacy = queryString.substring(keyUrl + 1);
        this.pharmacyId = idPharmacy;
        this.getNamePharmacy();
        this.buildForm();
    }

    // @ts-ignore
    convertNumber(value: any) {
        const maxFractionDigits = 2;
        const option = {};
        // @ts-ignore
        option['maximumFractionDigits'] = maxFractionDigits;
        if (value != '') {
            const countDecimals =
                (value && value.toString() && value.toString().split('.').length > 1)
                    ? value.toString().split('.')[1].length
                    : 0;
            const toFixed = countDecimals > maxFractionDigits
                ? (countDecimals - maxFractionDigits)
                : maxFractionDigits;
            // @ts-ignore
            const valueToFixed = (maxFractionDigits == 0)
                ? Number(value * (10 ** maxFractionDigits))
                : Number((value * (10 ** maxFractionDigits)).toFixed(toFixed));
            return parseFloat(value) ? (Math.floor(valueToFixed) / (10 ** maxFractionDigits)) : 0;
        }
    }

    toASCII(chars: any) {
        let ascii = '';
        for (let i = 0, l = chars.length; i < l; i++) {
            let c = chars[i].charCodeAt(0);

            // make sure we only convert half-full width char
            if (c >= 0xFF00 && c <= 0xFFEF) {
                c = 0xFF & (c + 0x20);
            }

            ascii += String.fromCharCode(c);
        }

        return ascii;
    }

    getAddress(postalCode: any) {
        this.address = '';
        this.ngxSpinner.show();
        this.monshinApi.getAddress(postalCode).subscribe((res) => {
            this.ngxSpinner.hide().then(() => {
                if (res) {
                    const data = res.data[0];
                    this.address = data.prefecture + data.city + data.town + data.koaza;
                }
            });
        }, (error) => {
            this.ngxSpinner.hide().then(() => {
                this.address = '';
            });
        });
    }

    getNamePharmacy() {
        const apigClientFactory = require('aws-api-gateway-client').default;
        AWS.config.region = environment.region;

        const cognitoidentity = new AWS.CognitoIdentity();
        const params = {
            IdentityPoolId: environment.IdentityPoolId,
        };

        cognitoidentity.getId(params, (err, datas) => {
            if (err) {
                console.log(err, err.stack);
                this.toaStr.error('インターネットに接続できません', '', {
                    positionClass: 'toast-center-center',
                });
            } else console.log(datas);

            const params = {
                IdentityId: String(datas?.IdentityId),
            };
            this.ngxSpinner.show();
            cognitoidentity.getCredentialsForIdentity(params, (err, datas) => {
                if (err) {
                    this.ngxSpinner.hide().then(() => {
                        this.ngxSpinner.hide().then((r) => console.log(err, err.stack));
                    });
                } else {
                    const apigClient = apigClientFactory.newClient({
                        invokeUrl: this.api + '/pharmacies/' + this.pharmacyId,
                        accessKey: datas.Credentials!.AccessKeyId,
                        secretKey: datas.Credentials!.SecretKey,
                        sessionToken: datas.Credentials!.SessionToken,
                        region: environment.region,
                    });

                    this.ngxSpinner.hide().then(() => {
                        apigClient.invokeApi('', '', 'get', '', '').then((res: any) => {
                            this.policy = res.data?.policy;
                            this.namePharmacy = res.data?.name;
                            this.checkDisableTerm = true;
                        }, (err: any) => {
                            console.log(err.message);
                            if (
                                !this.pharmacyId
                                || err.message === 'Request failed with status code 404'
                            ) {
                                this.toaStr.error('不正なアクセスです', '', {
                                    positionClass: 'toast-center-center',
                                });
                            } else {
                                this.toaStr.error(err, '', {
                                    positionClass: 'toast-center-center',
                                });
                            }
                            this.checkDisableTerm = false;
                        });
                    }).catch((error: any) => {
                        this.ngxSpinner.hide().then(() => {
                        });
                    });
                }
            });
        });
    }

    buildForm(val?: any) {
        this.formAgree = this.fb.group({
            // page1
            birthday: new UntypedFormControl(val ? val.birthday : '', []),
            first_name: new UntypedFormControl(val ? val.first_name : '', [Validators.required]),
            last_name: new UntypedFormControl(val ? val.last_name : '', [Validators.required]),
            last_name_kana: new UntypedFormControl(val ? val.last_name_kana : '', [
                Validators.required,
                Validators.maxLength(100),
            ]),
            first_name_kana: new UntypedFormControl(val ? val.first_name_kana : '', [
                Validators.required,
                Validators.maxLength(100),
            ]),
            sex: new UntypedFormControl(val ? val.sex : '', [Validators.required]),
            postal_code: new UntypedFormControl(val ? val.postal_code : '', [Validators.required]),
            address: new UntypedFormControl(val ? this.address : '', [Validators.required]),
            phone_number: new UntypedFormControl(val ? val.phoneNumber : '', [Validators.required]),
            // page2
            height: new UntypedFormControl(val ? val.height : '', []),
            weight: new UntypedFormControl(val ? val.weight : '', []),
            // page3
            prescription_note: new UntypedFormControl(val ? val.prescription_note : '', [
                Validators.required,
            ]),
            generic_state: new UntypedFormControl(val ? val.generic_state : '', [
                Validators.required,
            ]),
            // only choose generic_state = 3
            medicine_name: new UntypedFormControl(val ? val.medicine_name : '', []),
            written_in_prescription_note_desired_medicines: new UntypedFormControl(
                val ? val.written_in_prescription_note_desired_medicines : false,
                [],
            ),
            // page 4
            illness_state: new UntypedFormControl(val ? val.illness_state : '', [
                Validators.required,
            ]),
            illness_disease: new UntypedFormControl(this.diseaseCheckedList, []),
            other_disease: new UntypedFormControl(val ? val.other_disease : '', []),
            //  page5
            allergies_state: new UntypedFormControl(val ? val.allergies_state : '', [
                Validators.required,
            ]),
            allergies_value: new UntypedFormControl(this.allergiesCheckValueList, []),
            other_allergies: new UntypedFormControl(val ? val.other_allergies : '', []),
            //  page 6
            side_effect_state: new UntypedFormControl(val ? val.side_effect_state : '', [
                Validators.required,
            ]),
            side_effect_case: new UntypedFormControl(val ? val.side_effect_case : '', []),
            //  page 7
            adjuvant_remedy_state: new UntypedFormControl(val ? val.adjuvant_remedy_state : '', [
                Validators.required,
            ]),
            adjuvant_remedy_name: new UntypedFormControl(val ? val.adjuvant_remedy_name : '', []),
            written_in_prescription_note_adjuvant_remedy: new UntypedFormControl(
                val ? val.written_in_prescription_note_adjuvant_remedy : false,
                [],
            ),
            supplement_state: new UntypedFormControl(val ? val.supplement_state : '', [
                Validators.required,
            ]),
            supplement_name: new UntypedFormControl(val ? val.supplement_name : '', []),
            //  page 8
            pregnancy_state: new UntypedFormControl(val ? val.pregnancy_state : '', []),
            date_of_birth: new UntypedFormControl(val ? val.date_of_birth : '', []),
            breast_feeding: new UntypedFormControl(val ? val.breast_feeding : '', []),
            //  page 9
            drink: new UntypedFormControl(val ? val.drink : '', [Validators.required]),
            frequency: new UntypedFormControl(val ? val.frequency : '', []),
            drive: new UntypedFormControl(val ? val.drive : '', [Validators.required]),
            smoke_state: new UntypedFormControl(val ? val.smoke_state : '', [Validators.required]),
            smoke_count: new UntypedFormControl(val ? val.smoke_count : '', []),
            //  page 10
            dangerous_work: new UntypedFormControl(val ? val.dangerous_work : '', [
                Validators.required,
            ]),
            dangerous_hobby: new UntypedFormControl(val ? val.dangerous_hobby : '', [
                Validators.required,
            ]),
            meal_regularity: new UntypedFormControl(val ? val.meal_regularity : '', [
                Validators.required,
            ]),
            //  page 12
            contact_lens_state: new UntypedFormControl(val ? val.contact_lens_state : '', [
                Validators.required,
            ]),
        });

        // Check form change page 1
        this.formAgree.get('postal_code')?.valueChanges.subscribe((value) => {
            value.replace('-', '');
            const idZip = this.toASCII(value);

            const zip = idZip.replace(/(\d\d\d)(\d\d\d\d)/, '$1-$2');
            setTimeout(() => {
                const pattern = new RegExp('[0-9]{3}-?[0-9]{4}');
                const result = pattern.test(zip);
                if (this.formAgree.get('postal_code')?.value == '') {
                    this.validLengthPostalCode = false;
                    this.validRequiredPostalCode = true;
                    this.isPostalCodeRegexInvalid = false;
                } else {
                    this.validLengthPostalCode = false;
                    this.validRequiredPostalCode = false;
                    this.isPostalCodeRegexInvalid = !result;
                    const pattern1 = new RegExp('\\D');
                    const result1 = pattern1.test(idZip.replace('-', ''));
                    this.checkValidNumber = result1 ? false : true;
                    if (zip.length != 8 && zip.length != 0) {
                        this.validLengthPostalCode = true;
                        this.validRequiredPostalCode = false;
                        this.isPostalCodeRegexInvalid = false;
                        this.checkFocusPostalCode = true;
                    }
                }
            });
        });

        // Check form change page 4
        this.formAgree.get('illness_state')?.valueChanges.subscribe((value) => {
            if (value == 0) {
                const diseases = this.formAgree.get('illness_disease')?.value;
                if (diseases.length > 0) {
                    // @ts-ignore
                    diseases.map((disease) => {
                        if (disease.name == 'その他') {
                            this.formAgree.get('other_disease')?.setValidators(Validators.required);
                        }
                    });
                }
                this.formAgree.get('illness_disease')?.setValidators(Validators.required);
                this.formAgree.get('illness_disease')?.updateValueAndValidity();
            } else {
                this.formAgree.get('illness_disease')?.clearValidators();
                this.formAgree.get('other_disease')?.clearValidators();
                this.formAgree.get('illness_disease')?.updateValueAndValidity();
                this.formAgree.get('other_disease')?.updateValueAndValidity();
            }
        });

        // Check form change for page 5
        this.formAgree.get('allergies_state')?.valueChanges.subscribe((value) => {
            if (value == 0) {
                const allergies = this.formAgree.get('allergies_value')?.value;
                if (allergies.length > 0) {
                    // @ts-ignore
                    allergies.map((allergy) => {
                        if (allergy.name == 'その他') {
                            this.formAgree.get('other_allergies')?.setValidators(
                                Validators.required,
                            );
                        }
                    });
                }
                this.formAgree.get('allergies_value')?.setValidators(Validators.required);
                this.formAgree.get('allergies_value')?.updateValueAndValidity();
            } else {
                this.formAgree.get('allergies_value')?.clearValidators();
                this.formAgree.get('other_allergies')?.clearValidators();
                this.formAgree.get('allergies_value')?.updateValueAndValidity();
                this.formAgree.get('other_allergies')?.updateValueAndValidity();
            }
        });

        // Page 6
        this.formAgree.get('side_effect_state')?.valueChanges.subscribe((value) => {
            if (value == 0) {
                this.formAgree.get('side_effect_case')?.setValidators(Validators.required);
                this.formAgree.get('side_effect_case')?.updateValueAndValidity();
            } else {
                this.formAgree.get('side_effect_case')?.clearValidators();
                this.formAgree.get('side_effect_case')?.updateValueAndValidity();
            }
        });

        // Page 7
        this.formAgree.get('drink')?.valueChanges.subscribe((value) => {
            if (value == 0) {
                this.formAgree.get('frequency')?.setValidators(Validators.required);
                this.formAgree.get('frequency')?.updateValueAndValidity();
            } else {
                this.formAgree.get('frequency')?.clearValidators();
                this.formAgree.get('frequency')?.updateValueAndValidity();
            }
        });
        this.formAgree.get('smoke_state')?.valueChanges.subscribe((value) => {
            if (value == 0) {
                this.formAgree.get('smoke_count')?.setValidators(Validators.required);
                this.formAgree.get('smoke_count')?.updateValueAndValidity();
            } else {
                this.formAgree.get('smoke_count')?.clearValidators();
                this.formAgree.get('smoke_count')?.updateValueAndValidity();
            }
        });
        this.formAgree.get('contact_lens_state')?.valueChanges.subscribe((value) => {
            this.isDisable11thPage = this.contactLensValueListID?.length <= 0 && value == 0;
        });
        // page 8
        this.formAgree.get('sex')?.valueChanges.subscribe((value) => {
            if (value == 1) {
                this.formAgree.get('pregnancy_state')?.setValidators(Validators.required);
                this.formAgree.get('pregnancy_state')?.updateValueAndValidity();
                this.formAgree.get('breast_feeding')?.setValidators(Validators.required);
                this.formAgree.get('breast_feeding')?.updateValueAndValidity();
            } else {
                this.formAgree.get('pregnancy_state')?.clearValidators();
                this.formAgree.get('pregnancy_state')?.updateValueAndValidity();
                this.formAgree.get('breast_feeding')?.clearValidators();
                this.formAgree.get('breast_feeding')?.updateValueAndValidity();
            }
        });
        this.formAgree.get('pregnancy_state')?.valueChanges.subscribe((value) => {
            // 妊娠していない場合は出産予定日を空にする
            if (value == 1) {
                this.formAgree.get('date_of_birth')?.setValue('');
            }
        });
        return this.formAgree;
    }
    startFilling(): void {
        this.checkStart = true;
    }
    checkAgree(event: any) {
        this.checkAgreeTerm = event.target.checked;
    }

    previousPage(): any {
        if (this.page < 1 || this.page === 1) {
            return false;
        } else {
            this.page = this.page - 1;
        }
        setTimeout(() => {
            window.scroll(0, 0);
        });
    }

    nextPage(): any {
        if (this.page > this.totalPage || this.page === this.totalPage) {
            return false;
        } else if (this.page < this.totalPage) {
            this.page = this.page + 1;
        }
        setTimeout(() => {
            window.scroll(0, 0);
        });
    }

    get not_good_dosage_form(): UntypedFormArray {
        return this.formAgree.get('not_good_dosage_form') as UntypedFormArray;
    }

    processingBirthDay(): void {
        let year: any;
        switch (this.era) {
            case '令和':
                year = parseInt(this.year) + 2018;
                break;
            case '平成':
                year = parseInt(this.year) + 1988;
                break;
            case '昭和':
                year = parseInt(this.year) + 1925;
                break;
            case '大正':
                year = parseInt(this.year) + 1911;
                break;
            case '明治':
                year = parseInt(this.year) + 1867;
                break;
        }
        let birthDay = '';
        // @ts-ignore
        birthDay = year.toString() + '-' + this.month + '-' + this.date;
        // @ts-ignore
        this.yearBirth = year.toString();
        this.formAgree.get('birthday')?.setValue(birthDay);
    }
    actionSave() {
        if (this.clickOne) return;
        this.clickOne = true;
        this.processingBirthDay();
        this.formAgree.removeControl('frequently_eat_food');
        this.formAgree.removeControl('diathesis');
        this.formAgree.removeControl('contact_lens_value');
        this.formAgree.removeControl('favorite_food_and_lifestyle');
        this.formAgree.removeControl('not_good_dosage_form');
        this.formAgree.removeControl('pharmacy_id');
        this.formAgree.markAllAsTouched();
        if (this.formAgree.invalid) {
            return;
        }
        this.formAgree.addControl(
            'frequently_eat_food',
            new UntypedFormControl(this.frequentlyEatFoodListID, []),
        );
        this.formAgree.addControl('diathesis', new UntypedFormControl(this.diathesisListID, []));
        this.formAgree.addControl(
            'contact_lens_value',
            new UntypedFormControl(this.contactLensValueListID, []),
        );
        this.formAgree.addControl(
            'favorite_food_and_lifestyle',
            new UntypedFormControl(this.favoriteFoodLifestyleListID, []),
        );
        this.formAgree.addControl(
            'not_good_dosage_form',
            new UntypedFormControl(this.notGoodDosageFormListID, []),
        );
        this.formAgree.addControl('pharmacy_id', new UntypedFormControl(this.pharmacyId, []));
        let data = this.formAgree.getRawValue();

        // Page 3
        if (data.generic_state != 3) {
            data.medicine_name = '';
            data.written_in_prescription_note_desired_medicines = false;
        }

        // Page 4
        if (data.illness_state != 0) {
            data.illness_disease = [];
            data.other_disease = '';
        }
        if (data.illness_state == 0) {
            let count = 0;
            // @ts-ignore
            data.illness_disease.map((disease) => {
                if (disease.name == 'その他') {
                    count = 1;
                }
            });
            if (count == 0) {
                data.other_disease = '';
            }
        }

        // Page 5
        if (data.allergies_state != 0) {
            data.allergies_value = [];
            data.other_allergies = '';
        }
        if (data.allergies_state == 0) {
            let count = 0;
            // @ts-ignore
            data.allergies_value.map((allergy) => {
                if (allergy.name == 'その他') {
                    count = 1;
                }
            });
            if (count == 0) {
                data.other_allergies = '';
            }
        }

        // Page 6
        if (data.side_effect_state != 0) {
            data.side_effect_case = '';
        }

        // Page 7
        if (data.adjuvant_remedy_state != 0) {
            data.adjuvant_remedy_name = '';
            data.written_in_prescription_note_adjuvant_remedy = false;
        } else if (data.adjuvant_remedy_name == '') {
            // 「はい」かつ、薬剤名記入なしの場合
            data.adjuvant_remedy_name = '併用薬あり';
        }

        if (data.supplement_state != 0) {
            data.supplement_name = '';
        } else if (data.supplement_name == '') {
            // 「はい」かつ、薬剤名記入なしの場合
            data.supplement_name = '併用薬（OTC）あり';
        }
        // page 8
        if (data.sex == 0) {
            data.pregnancy_state = '';
            data.breast_feeding = '';
            data.date_of_birth = '';
        }

        // Page 9
        if (data.drink != 0) {
            data.frequency = '';
        }
        if (data.smoke_state != 0) {
            data.smoke_count = '';
        }

        // Page 12
        if (data.contact_lens_state != 0) {
            data.contact_lens_value = [];
        }

        const apigClientFactory = require('aws-api-gateway-client').default;
        AWS.config.region = environment.region;

        const cognitoidentity = new AWS.CognitoIdentity();
        const params = {
            IdentityPoolId: environment.IdentityPoolId,
        };

        cognitoidentity.getId(params, (err, datas) => {
            if (err) {
                console.log(err, err.stack);
                this.toaStr.error('インターネットに接続できません', '', {
                    positionClass: 'toast-center-center',
                });
                this.clickOne = false;
            } else {
                console.log(datas);
                this.clickOne = true;
            }
            const params = {
                IdentityId: String(datas?.IdentityId),
            };
            this.ngxSpinner.show();
            cognitoidentity.getCredentialsForIdentity(params, (err, datas) => {
                if (err) {
                    this.ngxSpinner.hide().then((r) => console.log(err, err.stack));
                    this.clickOne = false;
                } else {
                    const apigClient = apigClientFactory.newClient({
                        invokeUrl: this.api + '/interview',
                        accessKey: datas.Credentials!.AccessKeyId,
                        secretKey: datas.Credentials!.SecretKey,
                        sessionToken: datas.Credentials!.SessionToken,
                        region: environment.region,
                    });
                    apigClient.invokeApi('', '', 'post', '', data).then((res: any) => {
                        this.ngxSpinner.hide().then(() => {
                            this.isSubmitted = true;
                            this.checkEndPage = true;
                        });
                    }, (error: any) => {
                        this.ngxSpinner.hide().then(() => {
                            error.response?.data.map((err: any) => {
                                this.toaStr.error(err?.jp_message, '', {
                                    positionClass: 'toast-center-center',
                                });
                            });
                        });
                        this.clickOne = false;
                    }).catch((error: HandlerErrorCode) => {
                    });
                }
            });
        });
    }

    changeCheckDisease(event: any) {
        const checked = event.target.checked;
        this.diseaseList.forEach((it) => {
            if (event.target.value === it.name) {
                it.checked = checked;
            }
        });
        this.commonDiseaseValidation(event);
    }

    checkInputValueOfDiseaseText(event: any) {
        this.diseaseList.forEach((item) => {
            if (item.name === 'その他') {
                item.checked = event.target.textLength > 0;
            }
        });
        this.commonDiseaseValidation(event);
    }

    commonDiseaseValidation(event: any) {
        // @ts-ignore
        this.diseaseCheckedList = this.diseaseList.filter((disease) => disease.checked);
        this.formAgree.get('illness_disease')?.setValue(this.diseaseCheckedList);
        if (event.target.value == 'その他' && event.target.checked) {
            this.checkScrollPage4 = true;
            this.formAgree.get('other_disease')?.setValidators(Validators.required);
            this.formAgree.get('other_disease')?.updateValueAndValidity();
        }
        if (event.target.value == 'その他' && !event.target.checked) {
            this.checkScrollPage4 = false;
            this.formAgree.get('other_disease')?.clearValidators();
            this.formAgree.get('other_disease')?.updateValueAndValidity();
        }
    }

    async checkAllergiesValue(event: any) {
        const checked = event.target.checked;
        await this.allergiesValue.forEach((it) => {
            if (event.target.value === it.name) {
                it.checked = checked;
            }
        });
        this.commonAllergiesValidation(event);
    }

    checkInputValueOfAllergiesText(event: any) {
        this.allergiesValue.forEach((item) => {
            if (item.name === 'その他') {
                item.checked = event.target.textLength > 0;
            }
        });
        this.commonAllergiesValidation(event);
    }

    commonAllergiesValidation(event: any) {
        // @ts-ignore
        this.allergiesCheckValueList = this.allergiesValue.filter((allergy) => allergy.checked);
        this.formAgree.get('allergies_value')?.setValue(this.allergiesCheckValueList);
        if (event.target.value == 'その他' && event.target.checked) {
            this.checkScrollPage5 = true;
            this.formAgree.get('other_allergies')?.setValidators(Validators.required);
            this.formAgree.get('other_allergies')?.updateValueAndValidity();
        }
        if (event.target.value == 'その他' && !event.target.checked) {
            this.checkScrollPage5 = false;
            this.formAgree.get('other_allergies')?.clearValidators();
            this.formAgree.get('other_allergies')?.updateValueAndValidity();
        }
    }

    async checkFrequentlyEatFood(event: any) {
        const checked = event.target.checked;
        this.frequentlyEatFoodList.forEach((it) => {
            if (event.target.value == it.id) {
                it.checked = checked;
            }
        });
        this.frequentlyEatFoodListID = [];
        await this.frequentlyEatFoodList.forEach((value) => {
            if (value.checked) {
                // @ts-ignore
                this.frequentlyEatFoodListID.push(value.id);
            }
        });
    }
    async checkDiathesis(event: any) {
        const checked = event.target.checked;
        this.diathesisList.forEach((it) => {
            if (event.target.value == it.id) {
                it.checked = checked;
            }
        });
        this.diathesisListID = [];
        await this.diathesisList.forEach((value) => {
            if (value.checked === true) {
                // @ts-ignore
                this.diathesisListID.push(value.id);
            }
        });
    }
    async checkContactLensValue(event: any) {
        const checked = event.target.checked;
        this.contactLensValueList.forEach((it) => {
            if (event.target.value == it.id) {
                it.checked = checked;
            }
        });
        this.contactLensValueListID = [];
        await this.contactLensValueList.forEach((value) => {
            if (value.checked === true) {
                // @ts-ignore
                this.contactLensValueListID.push(value.id);
            }
        });
        this.isDisable11thPage = this.contactLensValueListID.length <= 0;
    }
    async checkFavoriteFoodLifestyleList(event: any) {
        const checked = event.target.checked;
        this.favoriteFoodLifestyleList.forEach((it) => {
            if (event.target.value == it.id) {
                it.checked = checked;
            }
        });
        this.favoriteFoodLifestyleListID = [];
        await this.favoriteFoodLifestyleList.forEach((value) => {
            if (value.checked === true) {
                // @ts-ignore
                this.favoriteFoodLifestyleListID.push(value.id);
            }
        });
    }
    async checkNotGoodDosageForm(event: any) {
        const checked = event.target.checked;
        this.notGoodDosageFormList.forEach((it) => {
            if (event.target.value == it.id) {
                it.checked = checked;
            }
        });
        this.notGoodDosageFormListID = [];
        await this.notGoodDosageFormList.forEach((value) => {
            if (value.checked === true) {
                // @ts-ignore
                this.notGoodDosageFormListID.push(value.id);
            }
        });
    }

    isDisableButton(): any {
        if (this.page == 1) {
            return this.formAgree.get('first_name')?.invalid
                || this.formAgree.get('last_name')?.invalid
                || this.formAgree.get('last_name_kana')?.invalid
                || this.formAgree.get('first_name_kana')?.invalid
                || this.formAgree.get('sex')?.invalid || this.formAgree.get('postal_code')?.invalid
                || this.formAgree.get('address')?.invalid
                || this.formAgree.get('phone_number')?.invalid
                || this.year == '' || this.month == '' || this.date == ''
                || this.isPostalCodeRegexInvalid || this.validLengthPostalCode;
        }
        if (this.page == 2) {
            return this.formAgree.get('height')?.invalid || this.formAgree.get('weight')?.invalid;
        }
        if (this.page == 3) {
            return this.formAgree.get('prescription_note')?.invalid
                || this.formAgree.get('generic_state')?.invalid
                || this.formAgree.get('written_in_prescription_note_desired_medicines')?.invalid;
        }
        if (this.page == 4) {
            return this.formAgree.get('illness_state')?.invalid
                || this.formAgree.get('illness_disease')?.invalid
                || this.formAgree.get('other_disease')?.invalid;
        }

        if (this.page == 5) {
            return this.formAgree.get('allergies_state')?.invalid
                || this.formAgree.get('allergies_value')?.invalid
                || this.formAgree.get('other_allergies')?.invalid;
        }
        if (this.page == 6) {
            return this.formAgree.get('side_effect_state')?.invalid
                || this.formAgree.get('side_effect_case')?.invalid;
        }
        if (this.page == 7) {
            return this.formAgree.get('adjuvant_remedy_state')?.invalid
                || this.formAgree.get('supplement_state')?.invalid;
        }
        if (this.page == 8 && this.formAgree.get('sex')?.value == 1) {
            return this.formAgree.get('pregnancy_state')?.invalid
                || this.formAgree.get('breast_feeding')?.invalid
                || this.formAgree.get('date_of_birth')?.invalid;
        }
        if (this.page == (this.pageTotalView == 14 ? 9 : 8)) {
            return this.formAgree.get('drink')?.invalid || this.formAgree.get('frequency')?.invalid
                || this.formAgree.get('drive')?.invalid
                || this.formAgree.get('smoke_state')?.invalid
                || this.formAgree.get('smoke_count')?.invalid;
        }
        if (this.page == (this.pageTotalView == 14 ? 10 : 9)) {
            return this.formAgree.get('dangerous_work')?.invalid
                || this.formAgree.get('dangerous_hobby')?.invalid
                || this.formAgree.get('meal_regularity')?.invalid;
        }
        if (this.page == (this.pageTotalView == 14 ? 12 : 11)) {
            return this.formAgree.get('contact_lens_state')?.invalid || this.isDisable11thPage;
        }
        return false;
    }

    onGettingEra(era: any): void {
        this.year = 1;
        this.processingBirthDay();
        this.appConfig.ERA.map((eraConfig) => {
            if (era == eraConfig.name) {
                // @ts-ignore
                this.eraYearList = eraConfig.years;
            }
        });
    }
    isValidYear() {
        if (this.year === '') {
            this.checkYear = true;
        }
    }
    isValidMonth() {
        if (this.month === '') {
            this.checkMonth = true;
        }
    }
    isValidDate() {
        if (this.date === '') {
            this.checkDate = true;
        }
    }

    onTrim(item: any): void {
        if (item === 'last_name') {
            this.checkFocusLastName = true;
        }
        if (item == 'first_name') {
            this.checkFocusFirstName = true;
        }
        if (item === 'last_name_kana') {
            this.checkFocusLastKana = true;
        }
        if (item === 'first_name_kana') {
            this.checkFocusFirstKana = true;
        }
        if (item === 'postal_code') {
            this.checkFocusPostalCode = true;
            if (this.formAgree.get('postal_code')?.value == '') {
                this.validLengthPostalCode = false;
                this.validRequiredPostalCode = true;
                this.isPostalCodeRegexInvalid = false;
            }
            const value = this.formAgree.get('postal_code')?.value;
            const idZip = this.toASCII(value.replace('-', ''));
            const postalCode = idZip.replace(/(\d\d\d)(\d\d\d\d)/, '$1-$2');
            this.formAgree.get('postal_code')?.setValue(postalCode, { emitEvent: false });
            setTimeout(() => {
                if (
                    postalCode.length === 8 && !this.isPostalCodeRegexInvalid
                    && this.formAgree.controls['postal_code'].dirty
                ) {
                    this.getAddress(postalCode);
                    this.checkFocusAddress = false;
                    this.formAgree.controls['postal_code'].markAsPristine();
                }
            });
        }
        setTimeout(() => {
            if (item === 'address') {
                this.checkFocusAddress = true;
            }
        });

        if (item === 'phone_number') {
            this.checkFocusPhone = true;
        }
        if (item === 'other_disease') {
            this.checkFocusOtherDisease = true;
        }
        if (item === 'other_allergies') {
            this.checkFocusOtherAllergies = true;
        }
        if (item === 'side_effect_case') {
            this.checkFocusSide = true;
        }

        // @ts-ignore
        if (this.formAgree.get(item)?.value && this.formAgree.get(item)?.dirty) {
            // @ts-ignore
            this.formAgree.get(item)?.setValue(this.formAgree.get(item)?.value.trim());
        }
    }
    yearChange() {
        this.month = '';
        this.eraDayList = [];
        this.date = '';
        this.processingBirthDay();
    }

    monthChange(month: any) {
        if (month == '02') {
            if (
                (this.yearBirth % 4 === 0 && this.yearBirth % 100 !== 0
                    && this.yearBirth % 400 !== 0)
                || (this.yearBirth % 100 === 0 && this.yearBirth % 400 === 0)
            ) {
                this.eraDayList = this.appConfig.DAY_IN_MONTH_29;
            } else {
                this.eraDayList = this.appConfig.DAY_IN_MONTH_28;
            }
        }
        if (
            month == '01' || month == '03' || month == '05' || month == '07' || month == '08'
            || month == '10' || month == '12'
        ) {
            // @ts-ignore
            this.eraDayList = this.appConfig.DAY_IN_MONTH;
        }
        if (month == '09' || month == '04' || month == '06' || month == '11') {
            // @ts-ignore
            this.eraDayList = this.appConfig.DAY_IN_MONTH_30;
        }
        this.date = '01';
    }

    sexChange(event: any) {
        const checkValue = event.target.value;
        if (checkValue == 0) {
            this.pageTotalView = 13;
        } else {
            this.pageTotalView = 14;
        }
    }

    closeBrow() {
        setTimeout(() => {
            location.reload();
        });
    }
}
