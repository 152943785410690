import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
    dsn: 'https://863a0a718e5c4fb5a3e708c1ed1c11b5@o476423.ingest.sentry.io/6598780',
    environment: environment.sentryEnvironment,
    beforeSend: (event) => {
        if (event.request?.data) {
            // POST時のbodyに問診票の個人情報が含まれる可能性があるので除去する
            delete event.request.data;
        }
        return event;
    },
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((err) => console.error(err));
