import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import dayjs from 'dayjs';

@Component({
    selector: 'date-picker',
    templateUrl: 'date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DateRangePickerOverview {
    @Input()
    label = '';
    @Input()
    dateOfBirth = '';
    @Output()
    dateChange = new EventEmitter<string | null>();

    formAgree!: UntypedFormGroup;

    readonly DATE_FORMAT = 'YYYY-MM-DD';
    // 出産予定日のカレンダーの最小値
    readonly dateOfBirthMin = dayjs().format(this.DATE_FORMAT);

    // 出産予定日のカレンダーの最大値
    readonly dateOfBirthMax = dayjs().add(40, 'week').format(this.DATE_FORMAT);

    constructor(private parentControl: ControlContainer) {}

    ngOnInit(): void {
        this.buildForm();
    }

    private buildForm(): void {
        this.formAgree = this.parentControl.control as UntypedFormGroup;
    }

    onDateChange(event: MatDatepickerInputEvent<Date>): void {
        if (
            dayjs(event.value).isValid()
            && this.formAgree.get('date_of_birth')?.status !== 'INVALID'
        ) {
            const value = dayjs(event.value).format('YYYY-MM-DD');
            this.formAgree.get('date_of_birth')!.setValue(value);
            this.dateChange.emit(value);
        } else {
            this.formAgree.get('date_of_birth')!.setValue(null);
            this.dateChange.emit(null);
        }
    }
}
