import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LaunchDarklyService {
    readonly key = 'feature-electronic-medical-questionnaire-app-maintenance-enabled';
    readonly regExp = /([^/]+?)?$/;

    maintenanceModeSubject = new BehaviorSubject(false);

    constructor() {
        const regExpExecArray = this.regExp.exec(location.href) || [];
        const context: LDClient.LDContext = { kind: 'user', key: regExpExecArray[0] };

        const client: LDClient.LDClient = LDClient.initialize(
            environment.launchDarklyClientId,
            context,
        );
        client.on('ready', () => {
            this.maintenanceModeSubject.next(client.variation(this.key, false) as boolean);
        });
    }
}
