import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LaunchDarklyService } from './services/launch-darkly.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    title = 'monshin';

    constructor(
        private _router: Router,
        private _launchDarklyService: LaunchDarklyService,
    ) {
        this._launchDarklyService.maintenanceModeSubject.subscribe((isMaintenanceMode) => {
            if (isMaintenanceMode) {
                this._router.navigate(['/403'], { skipLocationChange: true });
            }
        });
    }

    ngOnInit() {
        window.addEventListener('beforeunload', (event) => {
            event.preventDefault();
            event.returnValue = '';
        });
    }
}
