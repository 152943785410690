export const APP_CONFIG = {
    ERA: [
        {
            name: '令和',
            years: [
                { year: 1 },
                { year: 2 },
                { year: 3 },
                { year: 4 },
                { year: 5 },
                { year: 6 },
                { year: 7 },
                { year: 8 },
                { year: 9 },
                { year: 10 },
                { year: 11 },
                { year: 12 },
                { year: 13 },
                { year: 14 },
                { year: 15 },
                { year: 16 },
                { year: 17 },
                { year: 18 },
                { year: 19 },
                { year: 20 },
                { year: 21 },
                { year: 22 },
                { year: 23 },
                { year: 24 },
                { year: 25 },
                { year: 26 },
                { year: 27 },
                { year: 28 },
                { year: 29 },
                { year: 30 },
                { year: 31 },
                { year: 32 },
                { year: 33 },
                { year: 34 },
                { year: 35 },
                { year: 36 },
                { year: 37 },
                { year: 38 },
                { year: 39 },
                { year: 40 },
                { year: 41 },
                { year: 42 },
                { year: 43 },
                { year: 44 },
                { year: 45 },
                { year: 46 },
                { year: 47 },
                { year: 48 },
                { year: 49 },
                { year: 50 },
            ],
        },
        {
            name: '平成',
            years: [
                { year: 1 },
                { year: 2 },
                { year: 3 },
                { year: 4 },
                { year: 5 },
                { year: 6 },
                { year: 7 },
                { year: 8 },
                { year: 9 },
                { year: 10 },
                { year: 11 },
                { year: 12 },
                { year: 13 },
                { year: 14 },
                { year: 15 },
                { year: 16 },
                { year: 17 },
                { year: 18 },
                { year: 19 },
                { year: 20 },
                { year: 21 },
                { year: 22 },
                { year: 23 },
                { year: 24 },
                { year: 25 },
                { year: 26 },
                { year: 27 },
                { year: 28 },
                { year: 29 },
                { year: 30 },
                { year: 31 },
            ],
        },
        {
            name: '昭和',
            years: [
                { year: 1 },
                { year: 2 },
                { year: 3 },
                { year: 4 },
                { year: 5 },
                { year: 6 },
                { year: 7 },
                { year: 8 },
                { year: 9 },
                { year: 10 },
                { year: 11 },
                { year: 12 },
                { year: 13 },
                { year: 14 },
                { year: 15 },
                { year: 16 },
                { year: 17 },
                { year: 18 },
                { year: 19 },
                { year: 20 },
                { year: 21 },
                { year: 22 },
                { year: 23 },
                { year: 24 },
                { year: 25 },
                { year: 26 },
                { year: 27 },
                { year: 28 },
                { year: 29 },
                { year: 30 },
                { year: 31 },
                { year: 32 },
                { year: 33 },
                { year: 34 },
                { year: 35 },
                { year: 36 },
                { year: 37 },
                { year: 38 },
                { year: 39 },
                { year: 40 },
                { year: 41 },
                { year: 42 },
                { year: 43 },
                { year: 44 },
                { year: 45 },
                { year: 46 },
                { year: 47 },
                { year: 48 },
                { year: 49 },
                { year: 50 },
                { year: 51 },
                { year: 52 },
                { year: 53 },
                { year: 54 },
                { year: 55 },
                { year: 56 },
                { year: 57 },
                { year: 58 },
                { year: 59 },
                { year: 60 },
                { year: 61 },
                { year: 62 },
                { year: 63 },
                { year: 64 },
            ],
        },
        {
            name: '大正',
            years: [
                { year: 1 },
                { year: 2 },
                { year: 3 },
                { year: 4 },
                { year: 5 },
                { year: 6 },
                { year: 7 },
                { year: 8 },
                { year: 9 },
                { year: 10 },
                { year: 11 },
                { year: 12 },
                { year: 13 },
                { year: 14 },
                { year: 15 },
            ],
        },
        {
            name: '明治',
            years: [
                { year: 1 },
                { year: 2 },
                { year: 3 },
                { year: 4 },
                { year: 5 },
                { year: 6 },
                { year: 7 },
                { year: 8 },
                { year: 9 },
                { year: 10 },
                { year: 11 },
                { year: 12 },
                { year: 13 },
                { year: 14 },
                { year: 15 },
                { year: 16 },
                { year: 17 },
                { year: 18 },
                { year: 19 },
                { year: 20 },
                { year: 21 },
                { year: 22 },
                { year: 23 },
                { year: 24 },
                { year: 25 },
                { year: 26 },
                { year: 27 },
                { year: 28 },
                { year: 29 },
                { year: 30 },
                { year: 31 },
                { year: 32 },
                { year: 33 },
                { year: 34 },
                { year: 35 },
                { year: 36 },
                { year: 37 },
                { year: 38 },
                { year: 39 },
                { year: 40 },
                { year: 41 },
                { year: 42 },
                { year: 43 },
                { year: 44 },
                { year: 45 },
            ],
        },
    ],
    MONTH: [
        { month: '01' },
        { month: '02' },
        { month: '03' },
        { month: '04' },
        { month: '05' },
        { month: '06' },
        { month: '07' },
        { month: '08' },
        { month: '09' },
        { month: '10' },
        { month: '11' },
        { month: '12' },
    ],
    DAY_IN_MONTH: [
        { day: '01' },
        { day: '02' },
        { day: '03' },
        { day: '04' },
        { day: '05' },
        { day: '06' },
        { day: '07' },
        { day: '08' },
        { day: '09' },
        { day: '10' },
        { day: '11' },
        { day: '12' },
        { day: '13' },
        { day: '14' },
        { day: '15' },
        { day: '16' },
        { day: '17' },
        { day: '18' },
        { day: '19' },
        { day: '20' },
        { day: '21' },
        { day: '22' },
        { day: '23' },
        { day: '24' },
        { day: '25' },
        { day: '26' },
        { day: '27' },
        { day: '28' },
        { day: '29' },
        { day: '30' },
        { day: '31' },
    ],
    DAY_IN_MONTH_30: [
        { day: '01' },
        { day: '02' },
        { day: '03' },
        { day: '04' },
        { day: '05' },
        { day: '06' },
        { day: '07' },
        { day: '08' },
        { day: '09' },
        { day: '10' },
        { day: '11' },
        { day: '12' },
        { day: '13' },
        { day: '14' },
        { day: '15' },
        { day: '16' },
        { day: '17' },
        { day: '18' },
        { day: '19' },
        { day: '20' },
        { day: '21' },
        { day: '22' },
        { day: '23' },
        { day: '24' },
        { day: '25' },
        { day: '26' },
        { day: '27' },
        { day: '28' },
        { day: '29' },
        { day: '30' },
    ],
    DAY_IN_MONTH_28: [
        { day: '01' },
        { day: '02' },
        { day: '03' },
        { day: '04' },
        { day: '05' },
        { day: '06' },
        { day: '07' },
        { day: '08' },
        { day: '09' },
        { day: '10' },
        { day: '11' },
        { day: '12' },
        { day: '13' },
        { day: '14' },
        { day: '15' },
        { day: '16' },
        { day: '17' },
        { day: '18' },
        { day: '19' },
        { day: '20' },
        { day: '21' },
        { day: '22' },
        { day: '23' },
        { day: '24' },
        { day: '25' },
        { day: '26' },
        { day: '27' },
        { day: '28' },
    ],

    DAY_IN_MONTH_29: [
        { day: '01' },
        { day: '02' },
        { day: '03' },
        { day: '04' },
        { day: '05' },
        { day: '06' },
        { day: '07' },
        { day: '08' },
        { day: '09' },
        { day: '10' },
        { day: '11' },
        { day: '12' },
        { day: '13' },
        { day: '14' },
        { day: '15' },
        { day: '16' },
        { day: '17' },
        { day: '18' },
        { day: '19' },
        { day: '20' },
        { day: '21' },
        { day: '22' },
        { day: '23' },
        { day: '24' },
        { day: '25' },
        { day: '26' },
        { day: '27' },
        { day: '28' },
        { day: '29' },
    ],
};
