import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Input,
    Renderer2,
} from '@angular/core';

@Directive({
    selector: '[msbTooltip]',
})
// 要素を限定しない汎用的な Tooltip の Directive
export class TooltipDirective implements AfterViewInit {
    @Input()
    message!: string;
    @Input()
    adjustValueX = 0;
    @Input()
    adjustValueY = 0;
    @Input()
    adjustValueZ = 2;
    @Input()
    isText = false;
    @Input()
    isDisabledTouch = false;
    @Input()
    isAlignCenter = false;
    @Input()
    isAlignRight = false;
    @Input()
    isReverse = false;
    @Input()
    timer = 5000;

    isTooltipVisible = false;
    messageText?: string;
    messageElement!: HTMLSpanElement;

    tooltipTimer: any = null;

    // TODO: 原因不明だが touchstart が mouseover へ伝搬する事象が発生
    // 暫定的にタッチ操作のフラグを用意し mouseover への伝搬を停止する
    isExecTouchAction = false;

    constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {}

    ngAfterViewInit() {
        const tooltipTarget = this._elementRef.nativeElement as HTMLSpanElement;
        tooltipTarget.classList.add('glb-tooltip');
        this.messageElement = this._renderer.createElement('span');
        this._renderer.addClass(this.messageElement, 'glb-tooltip-message');
        this._renderer.appendChild(tooltipTarget, this.messageElement);
        if (this.isText) {
            tooltipTarget.classList.add('type-text');
        }
        if (this.isAlignCenter) {
            tooltipTarget.classList.add('type-align-center');
        }
        if (this.isAlignRight) {
            tooltipTarget.classList.add('type-align-right');
        }
    }

    @HostListener('touchstart', ['$event'])
    ontouchstart($event: TouchEvent) {
        if (this.isDisabledTouch) {
            this.isExecTouchAction = true;
            return;
        }
        if (!this.isTooltipVisible) {
            this.showTooltip($event.touches[0].pageX);
            this.tooltipTimer = setTimeout(() => {
                this.hideTooltip();
            }, this.timer);
        }
    }

    @HostListener('mouseover', ['$event'])
    onmouseover($event: MouseEvent) {
        $event.preventDefault();
        if (!this.isExecTouchAction && !this.isTooltipVisible) {
            this.showTooltip($event.pageX);
            this.tooltipTimer = setTimeout(() => {
                this.hideTooltip();
            }, this.timer);
        }
        // タッチ操作のフラグは都度、初期化
        // マウス操作 <=> タッチ操作 の往復を考慮すると必須
        if (this.isExecTouchAction) {
            this.isExecTouchAction = false;
        }
    }

    @HostListener('mouseout', ['$event'])
    onmouseout($event: MouseEvent) {
        $event.preventDefault();
        if (this.isTooltipVisible) {
            this.hideTooltip();
        }
    }

    showTooltip(pageX: number) {
        setTimeout(() => {
            // ツールチップ内テキストがリセットされるまで再表示しない
            if (this.messageElement.textContent?.length !== 0) {
                return;
            }

            this.messageText = this._renderer.createText(this.message);
            this._renderer.appendChild(this.messageElement, this.messageText);

            if (this.isReverse === true) {
                this.messageElement.style.bottom = '-'
                    + (this.messageElement.clientHeight + (15 + this.adjustValueY)) + 'px';
                this.messageElement.classList.add('is-tooltip-visible', 'is-reverse');
                this.messageElement.style.bottom = '-'
                    + (this.messageElement.clientHeight + (5 + this.adjustValueY)) + 'px';
            } else {
                this.messageElement.style.top = '-'
                    + (this.messageElement.clientHeight + (5 + this.adjustValueY)) + 'px';
                this.messageElement.classList.add('is-tooltip-visible');
                this.messageElement.style.top = '-'
                    + (this.messageElement.clientHeight + (15 + this.adjustValueY)) + 'px';
            }

            const viewportWidth = window.visualViewport?.width ?? 0;
            const tmpWidth = pageX + this.messageElement.clientWidth / 2;

            // ツールチップの x座標 を調整する処理
            // 見切れる場合、差分を計算し表示領域へ戻している
            if (tmpWidth > viewportWidth) {
                const tmpDiffWidth = tmpWidth - viewportWidth;
                // TODO：表示領域 右側 で見切れる場合のみ考慮。左側 で見切れる場合は、別途検討
                this.messageElement.style.marginLeft = '-'
                    + (this.messageElement.clientWidth / 2 + tmpDiffWidth + this.adjustValueX)
                    + 'px';
                // 見切れない場合、中横揃えで表示
            } else {
                this.messageElement.style.marginLeft = '-'
                    + (this.messageElement.clientWidth / 2 + this.adjustValueX) + 'px';
            }

            this.messageElement.style.left = '50%';
            this.messageElement.style.zIndex = this.adjustValueZ.toString();
            this.isTooltipVisible = true;
        }, 100);
    }

    hideTooltip() {
        this.messageElement.classList.remove('is-tooltip-visible');

        if (this.isReverse === true) {
            this.messageElement.style.bottom = '-'
                + (this.messageElement.clientHeight + (15 + this.adjustValueY)) + 'px';
        } else {
            this.messageElement.style.top = '-'
                + (this.messageElement.clientHeight + (5 + this.adjustValueY)) + 'px';
        }

        setTimeout(() => {
            if (this.isReverse === true) {
                this.messageElement.classList.remove('is-reverse');
            }
            this._renderer.removeChild(this.messageElement, this.messageText);
            this.isTooltipVisible = false;
            this.messageElement.style.zIndex = '-1';
            clearTimeout(this.tooltipTimer);
        }, 200);
    }
}
