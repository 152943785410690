import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent {
    @Input()
    policy: string = '';
    @Output()
    close = new EventEmitter();

    closeDialog(): void {
        this.close.emit();
    }
}
